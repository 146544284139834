import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from './ListItems';
import PropTypes from 'prop-types';
import CheckIfAuthenticated from "../authentication/common/helper"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import "./style.css"
import DeleteIcon from '@mui/icons-material/Delete';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CookieService from "encrypted-cookie";
import Visibility from '@mui/icons-material/Visibility';
import { ButtonBase, TextField } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function Copyright(props) {
  return (
    <Grid container direction='row' justifyContent="center" alignItems='center'>
      <Grid item xs={12}>
        <Typography variant="body2" color="text.secondary" align="center" {...props} style={{ whiteSpace: 'pre-line' }}>
          {'©'}{' '}{new Date().getFullYear()},{'made with 🖤 by '}
          <Link sx={{ color: "black" }}>
            Andal Packaging.
          </Link>
          <Typography variant="body2">
            {' Powered by'}{' '}
            <Link sx={{ color: "black" }} href="https://mernsoul.com/">
              MERNSoul.
            </Link>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
}
//<Typography fontWeight='bold'>made with<FavoriteIcon/></Typography>

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Dashboard() {
  const [count, setCount] = React.useState({});
  const [selectedRow, setSelectedRow] = useState([]);
  const [selected, setSelected] = useState([]);
  const [allProductJson, setAllProductJson] = React.useState({});
  const [err, setErr] = useState();
  const [id, setId] = useState();
  const [color, setColor] = useState();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [ismobile, setIsMobile] = useState(false);
  const theme = useTheme();
  const [openList, setOpenList] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleIncrement = (_id, val = null) => {
    var x = { ...count }
    if (val == null) {
      if (_id in x) {
        x[_id] = x[_id] + 1
      } else {
        x[_id] = 1
      }
    } else {
      x[_id] = parseInt(val)
    }
    setCount(x);
  };

  const handleDelete = (_id) => {
    var x = { ...count }
    if (_id in x) {
      delete x[_id];
    }
    setCount(x);
  };


  const [openDraw, setOpenDraw] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenDraw(true);
  };

  const handleDrawerClose = () => {
    setOpenDraw(false);
  };


  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  // const handleDelete = (x) => {
  //   if (x) {
  //     setColor('error')
  //     setOpenSnack(true)
  //     setErr("Please Contact Admin")
  //     return
  //   }

  // };

  useEffect(() => {
    const handleResize = () => {
      const maxWidth = '766px';
      const isMobileCondition = window.screen && window.innerWidth <= parseInt(maxWidth, 10);
      setIsMobile(isMobileCondition);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let navigate = useNavigate();
  useEffect(() => {
    getAllProducts()
    getCategories()
  }, []);

  function getAllProducts() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", CookieService.getCookie("token", "secretSolava"));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`/api/auth/getproducts?part=${true}`, requestOptions)
      .then(async (response) => {
        if (response.status == 200 || response.status == 400) {
          return { status_code: response.status, data: await response.json() };
        } else if (response.status == 401) {
          setColor('warning')
          setErr("Session Expired")
          CookieService.eraseCookie("token", "secretSolava")
          setOpenSnack(true)
          setTimeout(() => {
            navigate("/");
          }, 3000)
        } else {
          setColor("error");
          setErr('UnExpected Error');
          setOpenSnack(true);
        }
      })
      .then((result) => {
        if (result.status_code == 200) {
          // console.log(result,"ello ")
          var tmp = []
          result.data && result.data.data.forEach((val, ind) => {
            tmp.push(val);
            if ((result.data.data.length - 1) == ind) {
              setSelectedRow(tmp)
            }
          })
        }
      })
      .catch(error => console.log('error', error));
  }
  function getCategories() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", CookieService.getCookie("token", "secretSolava"));
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };

    fetch("/api/auth/getallcategory", requestOptions)
      .then(async (response) => {
        if (response.status == 200 || response.status == 400) {
          return { status_code: response.status, data: await response.json() };
        } else if (response.status == 401) {
          setColor('warning')
          setErr("Session Expired")
          CookieService.eraseCookie("token", "secretSolava")
          setOpenSnack(true)
          setTimeout(() => {
            navigate("/");
          }, 3000)
        } else {
          setColor("error");
          setErr('UnExpected Error');
          setOpenSnack(true);
        }
      })
      .then((result) => {
        if (result.status_code == 200) {
          var x = {}
          setSelected(result.data.data);
          for (var i in result.data.data) {
            for (var j in result.data.data[i].result) {
              x[result.data.data[i].result[j]["_id"]] = result.data.data[i].result[j];
            }
          }
          setAllProductJson(x)
        }
      })
      .catch(error => console.log('error', error));
  }
  function PlaceOrders() {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", CookieService.getCookie("token", "secretSolava"));
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "items": count,
      "stock_type":0
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`/api/auth/partorder`, requestOptions)
      .then(async (response) => {
        if (response.status == 200 || response.status == 400) {
          return { status_code: response.status, data: await response.json() };
        } else if (response.status == 401) {
          setColor('warning')
          setErr("Session Expired")
          CookieService.eraseCookie("token", "secretSolava")
          setOpenSnack(true)
          setTimeout(() => {
            navigate("/");
          }, 3000)
        } else {
          setColor("error");
          setErr('UnExpected Error');
          setOpenSnack(true);
        }
      })
      .then((result) => {
        if (result.status_code == 200) {
          setColor('success')
          setErr(result.data.msg)
          setOpenSnack(true)
          setCount({})
        } else {
          setColor('error')
          setErr(result.data.msg)
          setOpenSnack(true)
        }
      })
      .catch(error => console.log('error', error));
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={openDraw}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            sx={{
              marginRight: '36px',
              ...(openDraw && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, cursor: 'pointer' }}
            onClick={() => { navigate('/') }}
          >
            Daily Stock
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="opaque"
        anchor="left"
        open={openDraw}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {mainListItems}
          <ListItemButton>
            <ListItemIcon>
              <ShowChartIcon />
            </ListItemIcon>
            <ListItemText primary="Stocks Management" />
            {/* {openList ? <ExpandMore /> : <ExpandMore />} */}
          </ListItemButton>
          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} to="/dailystock">
                <ListItemIcon>
                  <OpenInNewIcon />
                </ListItemIcon>
                <ListItemText primary="Daily Stock" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }} to="/weeklystock">
                <ListItemIcon>
                  <OpenInNewIcon />
                </ListItemIcon>
                <ListItemText primary="Weekly Stock" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
        <Divider />
        <List>
          {secondaryListItems}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
      
        <Container maxWidth="lg" sx={{ mt: 3, mb: 3 }}>
          {selectedRow.length > 0 ? 
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{

                  display: 'flex',
                  flexDirection: 'column',
                  // height: 100,
                }}
              >
                <Grid container direction='row' justifyContent="space-evenly" alignItems="center">
                  <Grid item xs={12} md={12} ml={1} mt={1} lg={12} xl={12}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 500 }} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            {/* <StyledTableCell>Image</StyledTableCell> */}
                            <StyledTableCell align="left">Paroduct Name</StyledTableCell>
                            <StyledTableCell align="center">Unit</StyledTableCell>
                            <StyledTableCell align="center">Price</StyledTableCell>
                            <StyledTableCell align="center">GST</StyledTableCell>
                            <StyledTableCell align="center">Quantity</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedRow.map((row) => (
                            <StyledTableRow key={row.name}>
                              {/* <StyledTableCell component="th" scope="row">
                                {row.name}
                              </StyledTableCell> */}
                              <StyledTableCell align="left">{row.product_name}</StyledTableCell>
                              <StyledTableCell align="center">{row.product_unit}</StyledTableCell>
                              <StyledTableCell align="center">{row.product_rate}</StyledTableCell>
                              <StyledTableCell align="center">{row.product_gst}</StyledTableCell>
                              <StyledTableCell align="center">  <TextField type='number' value={row._id in count ? count[row._id] : 0} InputProps={{ alignItems: 'center' }} onChange={(e) => {
                                handleIncrement(row._id, e.target.value)
                              }} sx={{ width: 65 }} /></StyledTableCell></StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container direction='row' justifyContent='center' alignItems="center" mt={2}>
                <Grid item xs={12} align="right">
                  <Button variant='contained'onClick={handleClickOpen}>Submit</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid> : 
          <Grid container>
            <Grid item xs={12} align="center">
            Loading... 
            </Grid>
          </Grid>
          }
          <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
        <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Product Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="center">Quantity</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {Object.keys(count).length > 0 ? Object.keys(count).map((row) => (
          console.log(row,"hiii"),
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell variant="caption" color="text" fontWeight="medium" component="th" scope="row">{allProductJson[row]["product_name"]}</TableCell>
              <TableCell variant="caption" color="text" fontWeight="medium"  align="center"> {count[row]}</TableCell>
              <TableCell variant="caption" color="text" fontWeight="medium"  align="center">{<DeleteIcon sx={{ color: 'red', cursor: 'pointer' }} onClick={(e) => {
                                    handleDelete(row)
                                  }}
                                  >
                                    </DeleteIcon>}</TableCell>
            </TableRow>
          )) : "Cart Empty"}
        </TableBody>
      </Table>
    </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleClose}>cancel</Button>
          {Object.keys(count).length > 0 &&
          <Button variant='contained' onClick={()=>{
            PlaceOrders()
            handleClose()
          }} autoFocus>
            Confirm
          </Button>}
        </DialogActions>
      </Dialog>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }} open={openSnack} autoHideDuration={1500} onClose={handleCloseSnack}>
              <Alert onClose={handleCloseSnack} severity={color} sx={{ width: '100%' }}>
                {err}
              </Alert>
            </Snackbar>
          </Stack>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}