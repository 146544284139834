import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from './ListItems';
import PropTypes from 'prop-types';
import CheckIfAuthenticated from "../authentication/common/helper"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import "./style.css"
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CookieService from "encrypted-cookie";
import Visibility from '@mui/icons-material/Visibility';
import { ButtonBase, TextField } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { yellow } from '@mui/material/colors';
function Copyright(props) {
    return (
        <Grid container direction='row' justifyContent="center" alignItems='center'>
            <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary" align="center" {...props} style={{ whiteSpace: 'pre-line' }}>
                    {'©'}{' '}{new Date().getFullYear()},{'made with 🖤 by '}
                    <Link sx={{ color: "black" }}>
                        Andal Packaging.
                    </Link>
                    <Typography variant="body2">
                        {' Powered by'}{' '}
                        <Link sx={{ color: "black" }} href="https://mernsoul.com/">
                            MERNSoul.
                        </Link>
                    </Typography>
                </Typography>
            </Grid>
        </Grid>
    );
}
//<Typography fontWeight='bold'>made with<FavoriteIcon/></Typography>

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Dashboard() {
    const [productData, setProductData] = useState({});
    const [orderId, setOrderId] = useState();
    const [allProductJson, setAllProductJson] = React.useState({});
    const [allWeightProductJson, setweightAllProductJson] = React.useState({});
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [openInbox, setOpenInbox] = React.useState(false);
    const [openDeleteBox, setOpenDeleteBox] = React.useState(false);
    const [err, setErr] = useState();
    const [id, setId] = useState();
    const [color, setColor] = useState();
    const [openSnack, setOpenSnack] = React.useState(false);
    const [ismobile, setIsMobile] = useState(false);
    const [progress, setProgress] = React.useState('4');
    const theme = useTheme();
    const [openList, setOpenList] = React.useState(true);

    const handleClickList = () => {
      setOpenList(!openList);
    };
    const [openDraw, setOpenDraw] = React.useState(false);

    const handleDrawerOpen = () => {
      setOpenDraw(true);
    };
  
    const handleDrawerClose = () => {
      setOpenDraw(false);
    };
    const handleClickOpenDeleteBox = (e) => {
        setId(e)
        setOpenDeleteBox(true);
    };

    const handleCloseDeleteBox = () => {
        setOpenDeleteBox(false);
    };
    const handleClickOpenInbox = () => {
        setOpenInbox(true);
    };

    const handleCloseInbox = () => {
        setOpenInbox(false);
    };



    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };

    const handleDelete = (x) => {
        if (x) {
            setColor('error')
            setOpenSnack(true)
            setErr("Please Contact Admin")
            return
        }

    };

    useEffect(() => {
    }, [allProductJson ||allWeightProductJson ])

    useEffect(() => {
        const handleResize = () => {
            const maxWidth = '766px';
            const isMobileCondition = window.screen && window.innerWidth <= parseInt(maxWidth, 10);
            setIsMobile(isMobileCondition);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    let navigate = useNavigate();
    useEffect(() => {
        var isAuthenticated = CheckIfAuthenticated();
        if (!isAuthenticated) {
            let path = "/";
            navigate(path);
        } else {
            getMyOrder(null)
        }

    }, [null]);

    function getMyOrder(param=null) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", CookieService.getCookie("token", "secretSolava"));
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        var uri = `/api/auth/listmystockorders`
        if(param){
            uri += `?order_id=${param}`
        }else{
            uri += `?stock_type=${1}`
        }
        
        fetch(uri, requestOptions)
            .then(async (response) => {
                if (response.status == 200 || response.status == 400) {
                    return { status_code: response.status, data: await response.json() };
                }else if (response.status == 401) {
                    setColor('warning')
                    setErr("Session Expired")
                    CookieService.eraseCookie("token", "secretSolava")
                    setOpenSnack(true)
                    setTimeout(() => {
                      navigate("/");
                    }, 3000)
                  } else {
                    setColor("error");
                    setErr('UnExpected Error');
                    setOpenSnack(true);
                  }
            })
            .then((result) => {
                if (result.status_code == 200) {
                    if (!param) {
                        var x = {}
                        var y = {}
                        setSelectedRow(result.data.data);
                        for (var i in result.data.data) {
                            if (!(Object.keys(x).includes(i))) {
                                x[result.data.data[i]["_id"]] = {}
                            }
                            for (var j in result.data.data[i].items) {
                                x[result.data.data[i]["_id"]][j] = result.data.data[i].items[j];
                            }
                            
                        }
                        for (var p in result.data.data) {
                            if (!(Object.keys(y).includes(p))) {
                                y[result.data.data[p]["_id"]] = {}
                            }
                            for (var e in result.data.data[p].weight_products) {
                                y[result.data.data[p]["_id"]][e] = result.data.data[p].weight_products[e];
                            }
                            
                        }
                        setAllProductJson(x)
                        setweightAllProductJson(y)
                    } else {
                        var t = {}
                        result.data &&
                            result.data.data &&
                            result.data.data.forEach((elem, ind) => {
                                t[elem["_id"]] = elem
                                if (ind + 1 == result.data.data.length) {
                                    setProductData(t)
                                }
                            });
                    }
                }
            })
            .catch(error => console.log('error', error));
    }
  
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
     <AppBar position="absolute" open={openDraw}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            sx={{
              marginRight: '36px',
              ...(openDraw && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, cursor: 'pointer' }}
            onClick={() => { navigate('/') }}
          >
             WeeklyStock History
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="opaque"
        anchor="left"
        open={openDraw}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
        {mainListItems}
        <ListItemButton>
        <ListItemIcon>
          <ShowChartIcon />
        </ListItemIcon>
        <ListItemText primary="Stocks Management" />
        {/* {openList ? <ExpandMore /> : <ExpandMore />} */}
      </ListItemButton>
          <Collapse in={openList} timeout="auto" unmountOnExit>
             <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} to="/dailystock">
            <ListItemIcon>
              <OpenInNewIcon />
            </ListItemIcon>
            <ListItemText primary="Daily Stock" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} to="/weeklystock">
            <ListItemIcon>
              <OpenInNewIcon />
            </ListItemIcon>
            <ListItemText primary="Weekly Stock" />
          </ListItemButton>
        </List>
      </Collapse>
        </List>
        <Divider />
        <List>
        {secondaryListItems}
        </List>
      </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 3, mb: 3 }}>
                    <Grid container spacing={3}>
                        {/* Chart */}
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper
                                sx={{

                                    display: 'flex',
                                    flexDirection: 'column',
                                    // height: 100,
                                }}
                            >
                                <Grid container direction='row' justifyContent="space-evenly" alignItems="center">
                                    <Grid item xs={12} md={12} ml={1} mt={1} lg={12} xl={12}>
                                        <TableContainer component={Paper} >
                                            <Table fullWidth aria-label="simple table">
                                                <TableHead>
                                                    <StyledTableRow>
                                                        <StyledTableCell sx={{ fontWeight: 'bold' }} align="center">Date</StyledTableCell>
                                                        <StyledTableCell sx={{ fontWeight: 'bold' }} align="center">Time</StyledTableCell>
                                                        <StyledTableCell sx={{ fontWeight: 'bold' }} align="center">Order Count</StyledTableCell>
                                                        {/* <StyledTableCell sx={{ fontWeight: 'bold' }} align="center">Status</StyledTableCell> */}
                                                        <StyledTableCell sx={{ fontWeight: 'bold' }} align="center">View Orders</StyledTableCell>
                                                        {/* <StyledTableCell sx={{ fontWeight: 'bold' }} align="center">Action</StyledTableCell> */}
                                                    </StyledTableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {selectedRow.map((row) => (
                                                        <StyledTableRow
                                                            key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <StyledTableCell align="center" variant="caption" color="text" fontWeight="medium"> {new Date(row.createdAt).toLocaleDateString('en-GB')}</StyledTableCell>
                                                            <StyledTableCell align="center" variant="caption" color="text" fontWeight="medium">{new Date(row.createdAt).toLocaleTimeString("en-US")}</StyledTableCell>
                                                            <StyledTableCell align="center" variant="caption" color="text" fontWeight="medium">{row.items && Object.keys(row.items).length} </StyledTableCell>
                                                            {/* <StyledTableCell align="center">{orderStatusMapping(row.state)}</StyledTableCell> */}
                                                            <StyledTableCell align="center"><ButtonBase onClick={() => {
                                                                handleClickOpenInbox()
                                                                getMyOrder(row._id)
                                                                setSelectedRowId(row._id)
                                                                setOrderId(row.state)
                                                            }}><Visibility /></ButtonBase></StyledTableCell>
                                                            {/* {row.state < 2 ? <StyledTableCell align="center"><ButtonBase component={Link} to={`/placeorder?order_id=${row._id}`}><BorderColorIcon sx={{ cursor: "pointer", color: 'red' }} /></ButtonBase>&nbsp;&nbsp;<ButtonBase onClick={(e) => {
                                                                handleClickOpenDeleteBox(row._id)
                                                            }} ><CancelOutlinedIcon sx={{ cursor: "pointer", color: 'red' }} /></ButtonBase></StyledTableCell> : <StyledTableCell align="center"><BorderColorIcon onClick={handleDelete} disabled={true} sx={{ color: 'grey' }} />&nbsp;&nbsp;<CancelOutlinedIcon onClick={handleDelete} disabled={true} sx={{ color: 'grey' }} /></StyledTableCell>}
                                                            {row.state < 2 ? <StyledTableCell align="center"></StyledTableCell> : <StyledTableCell align="center"><CancelOutlinedIcon disabled={true}  sx={{color:'grey'}}/></StyledTableCell> } */}
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Dialog
                        open={openDeleteBox}
                        onClose={handleCloseDeleteBox}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Are you sure you want to cancel the order?"}
                        </DialogTitle>
                        <DialogContent>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' onClick={handleCloseDeleteBox}>cancel</Button>
                            <Button variant='contained' onClick={() => {
                                handleCloseDeleteBox()
                                // orderStatus()
                            }} autoFocus>
                                yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openInbox}
                        onClose={handleCloseInbox}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                    >
                        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 'bold' }} align='center'>
                            {"Order Item List"}
                        </DialogTitle>
                        <Divider sx={{ boxShadow: 3 }} />
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <TableContainer component={Paper}>
                                    <Table fullWidth aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold' }} align="left">Product&nbsp;Name</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Quantity</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Weight&nbsp;(g)</TableCell>
                                                {/* <TableCell sx={{ fontWeight: 'bold' }} align="center">GST&nbsp;(₹)</TableCell> */}
                                                {/* <TableCell sx={{ fontWeight: 'bold' }} align="center">Total&nbsp;(₹)</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(productData).map((row) => (
                                                <TableRow
                                                    key={row}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {productData[row].product_name}
                                                    </TableCell>
                                                    <TableCell align="center">{allProductJson[selectedRowId][row]}</TableCell>
                                                    <TableCell align="center">{ row in allWeightProductJson[selectedRowId] ? allWeightProductJson[selectedRowId][row] : "--"}</TableCell>
                                                </TableRow>
                                            ))}
                                            {/* <TableRow
                                                key={"Total"}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                                    {"Total"}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {""}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {Object.keys(productData).length > 0 && "₹" + Object.keys(productData).reduce((a, v) => a = a + parseFloat(allProductJson[selectedRowId][v] * parseFloat(productData[v].product_rate) + ((productData[v].product_gst / 100) * (allProductJson[selectedRowId][v] * parseFloat(productData[v].product_rate)))), 0).toFixed(2)}
                                                </TableCell>
                                            </TableRow> */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' onClick={handleCloseInbox}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }} open={openSnack} autoHideDuration={1500} onClose={handleCloseSnack}>
                            <Alert onClose={handleCloseSnack} severity={color} sx={{ width: '100%' }}>
                                {err}
                            </Alert>
                        </Snackbar>
                    </Stack>
                    <Copyright sx={{ pt: 4 }} />
                </Container>
            </Box>
        </Box>
    );
}