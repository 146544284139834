import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CookieService from "encrypted-cookie";

const LogoutButton = () => {

  const handleLogout = () => {
    CookieService.eraseCookie("token","secretSolava"); // Remove the cookie named 'token'
    window.location.href = '/'; // Redirect to the login page
  };

  return (
    <button onClick={handleLogout}>Logout</button>
  );
};

export default LogoutButton;