import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import CookieService from "encrypted-cookie";
import CheckIfAuthenticated from "./common/helper"

// import Image from "../../../public/loginimage.jpeg"
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Copyright(props) {
    return (
        <Grid container direction='row' justifyContent="center" alignItems='center'>
            <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary" align="center" {...props} style={{whiteSpace: 'pre-line'}}>
                    {'©'}{' '}{new Date().getFullYear()},{'made with 🖤 by '}
                    <Link sx={{color:"black"}}>
                        Andal Packaging.
                    </Link>
                    <Typography variant="body2">
                    {' Powered by'}{' '}
                    <Link sx={{color:"black"}} href="https://mernsoul.com/">
                       MERNSoul.
                    </Link>
                    </Typography>
                </Typography>
            </Grid>
        </Grid>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignInSide() {
    let navigate = useNavigate();
    useEffect(() => {
        var isAuthenticated = CheckIfAuthenticated();
        if (isAuthenticated) {
            let path = "/placeorder";
            navigate(path);
        }
    }, []);
    const [open, setOpen] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = useState();
    const [password, setPassword] = useState();
    const [err, setErr] = useState();
    const [color, setColor] = useState();
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(true);
    console.log(loadings, "jhgedu")
    //snackbar here
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    function login() {
        setColor("info")
        setErr("Login on progress.")
        setOpen(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", CookieService.getCookie("token","secretSolava"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "phoneNumber": phoneNumber,
            "password": password,
            "type": "1"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("/api/auth/login", requestOptions)
            .then(async (response) => {
                if (response.status == 200 || response.status == 400) {
                    return { status_code: response.status, data: await response.json() };
                }
                return { status_code: response.status, data: { msg: "Un-Expected Error" } };
            })
            .then((result) => {
                if (result.status_code == 200) {
                    console.log(result, "e")
                    setColor("success")
                    setErr(result.data.msg)
                    setLoadings(result.data.msg)
                    setOpen(true)
                    CookieService.setCookie("token", result.data.token, 7,"secretSolava");
                    setTimeout(() => {
                        console.log("Hi")
                        navigate('/placeorder')
                    }, 750)
                } else {
                    setColor("error")
                    setErr(result.data.msg)
                    setOpen(true)
                }
            })
            .catch(error => console.log('error', error));

    }
    return (
        <>

            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(/loginimage.jpeg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />

                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="phonenumber"
                                label="Username"
                                name="Username"
                                autoComplete="phonenumber"
                                autoFocus
                                onChange={(e) => {
                                    setPhoneNumber(e.target.value)
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                            />
                            {/* <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            /> */}
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={() => {
                                    if (!phoneNumber) {
                                        setColor('error')
                                        setErr('Please Enter Phone Number')
                                        setOpen(true)
                                        return
                                    }
                                    if (!password) {
                                        setColor('error')
                                        setErr('Please Enter Password')
                                        setOpen(true)
                                        return
                                    }
                                    login()
                                }}
                            >
                                Sign In
                            </Button>
                            {/* <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid> */}
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }} open={open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                                {err}
                            </Alert>
                        </Snackbar>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
}