import CookieService from "encrypted-cookie";

const CheckIfAuthenticated = () => {
    if(!CookieService.getCookie('token',"secretSolava")) {
        return false
    }
    return true
}

export default CheckIfAuthenticated;
