import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignInSide from './components/authentication/Signin';
import OrderHistory from './components/dashboard/Orderhistory';
import LogoutButton from './components/authentication/signout';
import PlaceOrder from './components/dashboard/Placeorder';
import Parkstack from './components/dashboard/Partstack';
import Weeklystack from './components/dashboard/Weeklystack';
import WeeklystackHistory from './components/dashboard/WOrderhistory';
import PartstackHistory from './components/dashboard/POrderhistory';
export default function App() {
  return (
    <BrowserRouter>
    <div>
    <Routes>
        <Route path="/" element={<SignInSide/>}/>
      <Route path="/placeorder" element={<PlaceOrder/>}/>
      <Route path="/orderhistory" element={<OrderHistory/>}/>
      <Route path="/dailystock-history" element={<PartstackHistory/>}/>
      <Route path="/weeklystock-history" element={<WeeklystackHistory/>}/>
      <Route path="/dailystock" element={<Parkstack/>}/>
      <Route path="/weeklystock" element={<Weeklystack/>}/>
      <Route path="/logout" element={<LogoutButton/>}/>
    </Routes>
    </div>
  </BrowserRouter>

  );
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
