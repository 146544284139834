import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from './ListItems';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CheckIfAuthenticated from "../authentication/common/helper"
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import "./style.css"
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CookieService from "encrypted-cookie";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useLocation } from 'react-router-dom';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ListSubheader from '@mui/material/ListSubheader';
import Collapse from '@mui/material/Collapse';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
function Copyright(props) {
  return (
    <Grid container direction='row' justifyContent="center" alignItems='center'>
      <Grid item xs={12}>
        <Typography variant="body2" color="text.secondary" align="center" {...props} style={{ whiteSpace: 'pre-line' }}>
          {'©'}{' '}{new Date().getFullYear()},{'made with 🖤 by '}
          <Link sx={{ color: "black" }}>
            Andal Packaging.
          </Link>
          <Typography variant="body2">
            {' Powered by'}{' '}
            <Link sx={{ color: "black" }} href="https://mernsoul.com/">
              MERNSoul.
            </Link>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
}
//<Typography fontWeight='bold'>made with<FavoriteIcon/></Typography>
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



export default function Dashboard() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryValue = searchParams.get('order_id') || '';
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [count, setCount] = React.useState({});
  const [allProductJson, setAllProductJson] = React.useState({});
  const [selectedRow, setSelectedRow] = useState([]);
  const [openItem, setOpenItems] = React.useState(false);
  const [err, setErr] = useState();
  const [color, setColor] = useState();
  const [ismobile, setIsMobile] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openImg, setOpenImg] = React.useState(false);
  const [img, setImg] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = useState()
  const [shopname, setShopName] = useState()
  const [shop_id, setShopId] = useState()
  const [shopLocation, setShopLocation] = useState()
  const [ownerName, setOwnerName] = useState()
  const [currentpassword, setCurrentPassword] = useState()
  const [newpassword, setNewPassword] = useState()
  const [confirmpassword, setConfirmPassword] = useState()
  const [valueProfile, setValueProfile] = React.useState(0);
  const theme = useTheme();
  console.log(allProductJson,"ui")
  const [openList, setOpenList] = React.useState(true);

  const handleClickList = () => {
    setOpenList(!openList);
  };
  const [openDraw, setOpenDraw] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenDraw(true);
  };

  const handleDrawerClose = () => {
    setOpenDraw(false);
  };


  const handleChangeProfile = (event, newValue) => {
    setValueProfile(newValue);
  };

  const handleClickOpenProfile = () => {
    setOpenProfile(true);
  };

  const handleCloseProfile = () => {
    setOpenProfile(false);
  };
  const handleClickOpenImg = (e, v) => {
    setOpenImg(true);
    setImg(v.product_logo)
  };

  const handleCloseImg = () => {
    setOpenImg(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleClickOpenItem = () => {
    setOpenItems(true);
  };

  const handleCloseItem = () => {
    setOpenItems(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const maxWidth = '766px';
      const isMobileCondition = window.screen && window.innerWidth <= parseInt(maxWidth, 10);
      setIsMobile(isMobileCondition);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleIncrement = (_id, val = null) => {
    var x = { ...count }
    if (val == null) {
      if (_id in x) {
        x[_id] = x[_id] + 1
      } else {
        x[_id] = 1
      }
    } else {
      x[_id] = parseInt(val)
    }
    setCount(x);
  };

  const handleDecrement = (_id) => {
    var x = { ...count }
    console.log(x, "<---------")
    if (_id in x) {
      if (x[_id] > 1) {
        x[_id] = x[_id] - 1
      } else {
        delete x[_id];
      }
    }
    setCount(x);
  };

  const handleDelete = (_id) => {
    var x = { ...count }
    if (_id in x) {
      delete x[_id];
    }
    setCount(x);
  };
  const handleLeftArrowBtn = () => {
    var x = value
    if (x > 0) {
      setValue(x - 1);
    }

  }
  const handleRightArrowBtn = () => {
    var x = value
    if (x < 4) {
      setValue(x + 1);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  let navigate = useNavigate();
  useEffect(() => {
    var isAuthenticated = CheckIfAuthenticated();
    if (!isAuthenticated) {
      let path = "/";
      navigate(path);
    } else {
      getCategories()
    }

  }, []);

  function getMyOrder(param, isProductDetail = false) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", CookieService.getCookie("token", "secretSolava"));
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    if (!isProductDetail) {
      var uri = `api/auth/listmyorders?order_id=${param}`;
    } else {
      var uri = `/api/auth/getorderitems?order_id=${param}`;
    }
    fetch(uri, requestOptions)
      .then(async (response) => {
        if (response.status == 200 || response.status == 400) {
          return { status_code: response.status, data: await response.json() };
        } else if (response.status == 401) {
          setColor('warning')
          setErr("Session Expired")
          CookieService.eraseCookie("token", "secretSolava")
          setOpenSnack(true)
          setTimeout(() => {
            navigate("/");
          }, 3000)
        } else {
          setColor("error");
          setErr('UnExpected Error');
          setOpenSnack(true);
        }
      })
      .then((result) => {
        if (result.status_code == 200) {
          if (!isProductDetail) {
            setCount(result.data.data.items)
          }
        } else {
          setColor('error')
          setErr(result.data.msg)
          setOpenSnack(true)
          navigate("/placeorder");
        }
      })
      .catch(error => console.log('error', error));
  }
  function editOrders() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", CookieService.getCookie("token", "secretSolava"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "items": count,
      "_id": queryValue
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`/api/auth/editOrders`, requestOptions)
      .then(async (response) => {
        if (response.status == 200 || response.status == 400) {
          return { status_code: response.status, data: await response.json() };
        } else if (response.status == 401) {
          setColor('warning')
          setErr("Session Expired")
          CookieService.eraseCookie("token", "secretSolava")
          setOpenSnack(true)
          setTimeout(() => {
            navigate("/");
          }, 3000)
        } else {
          setColor("error");
          setErr('UnExpected Error');
          setOpenSnack(true);
        }
      })
      .then((result) => {
        if (result.status_code == 200) {
          setColor('success')
          setErr(result.data.msg)
          setOpenSnack(true)
          setCount({})
          navigate("/placeorder");
        } else {
          setColor('error')
          setErr(result.data.msg)
          setOpenSnack(true)
        }
      })
      .catch(error => console.log('error', error));
  }
  function getCategories() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", CookieService.getCookie("token", "secretSolava"));
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };

    fetch("/api/auth/getallcategory", requestOptions)
      .then(async (response) => {
        if (response.status == 200 || response.status == 400) {
          return { status_code: response.status, data: await response.json() };
        } else if (response.status == 401) {
          setColor('warning')
          setErr("Session Expired")
          CookieService.eraseCookie("token", "secretSolava")
          setOpenSnack(true)
          setTimeout(() => {
            navigate("/");
          }, 3000)
        } else {
          setColor("error");
          setErr('UnExpected Error');
          setOpenSnack(true);
        }
      })
      .then((result) => {
        if (result.status_code == 200) {
          var x = {}
          setSelectedRow(result.data.data);
          for (var i in result.data.data) {
            for (var j in result.data.data[i].result) {
              x[result.data.data[i].result[j]["_id"]] = result.data.data[i].result[j];
            }
          }
          setAllProductJson(x)
          if (queryValue.length) {
            getMyOrder(queryValue)
          }
        }
      })
      .catch(error => console.log('error', error));
  }

  function getMtShop() {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", CookieService.getCookie("token", "secretSolava"));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("/api/auth/getmyshop", requestOptions)
      .then(async (response) => {
        if (response.status == 200 || response.status == 400) {
          return { status_code: response.status, data: await response.json() };
        } else if (response.status == 401) {
          setColor('warning')
          setErr("Session Expired")
          CookieService.eraseCookie("token", "secretSolava")
          setOpenSnack(true)
          setTimeout(() => {
            navigate("/");
          }, 3000)
        } else {
          setColor("error");
          setErr('UnExpected Error');
          setOpenSnack(true);
        }
      })
      .then((result) => {
        if (result.status_code == 200) {
          console.log(result.data, "idyi")
          setPhoneNumber(result.data.data.phoneNumber)
          setShopName(result.data.data.shopName)
          setOwnerName(result.data.data.ownerName)
          setShopLocation(result.data.data.shopLocation)
          setShopId(result.data.data.shop_id)
        } else {
          setColor('error')
          setErr(result.data.msg)
          setOpenSnack(true)
        }
      })
      .catch(error => console.log('error', error));
  }

  function PlaceOrders() {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", CookieService.getCookie("token", "secretSolava"));
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "items": count
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`/api/auth/order`, requestOptions)
      .then(async (response) => {
        if (response.status == 200 || response.status == 400) {
          return { status_code: response.status, data: await response.json() };
        } else if (response.status == 401) {
          setColor('warning')
          setErr("Session Expired")
          CookieService.eraseCookie("token", "secretSolava")
          setOpenSnack(true)
          setTimeout(() => {
            navigate("/");
          }, 3000)
        } else {
          setColor("error");
          setErr('UnExpected Error');
          setOpenSnack(true);
        }
      })
      .then((result) => {
        if (result.status_code == 200) {
          setColor('success')
          setErr(result.data.msg)
          setOpenSnack(true)
          setCount({})
        } else {
          setColor('error')
          setErr(result.data.msg)
          setOpenSnack(true)
        }
      })
      .catch(error => console.log('error', error));
  }

  // function userProfileEdit() {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", CookieService.getCookie("token", "secretSolava"));
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     "phoneNumber": phoneNumber,
  //     "password": password,
  //     "shopName": shopname,
  //     "shopLocation": shopLocation,
  //     "ownerName": ownerName
  //   });

  //   var requestOptions = {
  //     method: "PUT",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };
  //   fetch("/api/auth/edituser", requestOptions)
  //     .then(async (response) => {
  //       if (response.status == 200 || response.status == 400) {
  //         return { status_code: response.status, data: await response.json() };
  //       }
  //     })
  //     .then((result) => {
  //       if (result.status_code == 200) {
  //         setColor("success");
  //         setErr(result.data.msg)
  //         setOpenSnack(true)
  //       } else {
  //         setColor("error");
  //         setErr(result.data.msg)
  //         setOpenSnack(true)
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // }

  function updatePassword() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", CookieService.getCookie("token", "secretSolava"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "currentPassword": currentpassword,
      "newpassword": newpassword,
      "confirmpassword": confirmpassword
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("/api/auth/edituserpassword", requestOptions)
      .then(async (response) => {
        if (response.status == 200 || response.status == 400) {
          return { status_code: response.status, data: await response.json() };
        } else if (response.status == 401) {
          setColor('warning')
          setErr("Session Expired")
          setOpenSnack(true)
          CookieService.eraseCookie("token", "secretSolava")
          setTimeout(() => {
            navigate("/");
          }, 3000)
        } else {
          setColor("error");
          setErr('UnExpected Error');
          setOpenSnack(true);
        }
      })
      .then((result) => {
        if (result.status_code == 200) {
          // setColor("success");
          // setErr(result.data.msg)
          // setOpenSnack(true)
          setConfirmPassword()
          setNewPassword()
          setCurrentPassword()
          setColor("warning");
          setErr(" Please Re-Login")
          setOpenSnack(true)
          CookieService.eraseCookie("token", "secretSolava")
          setTimeout(() => {
            navigate("/")
          }, 800)
        } else {
          setColor("error");
          setErr(result.data.msg)
          setOpenSnack(true)
        }
      })
      .catch(error => console.log('error', error));
  }


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* <AppBar position="fixed" open={openDraw}> */}
      <AppBar position="absolute" open={openDraw}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            sx={{
              marginRight: '36px',
              ...(openDraw && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, cursor: 'pointer' }}
            onClick={() => { navigate('/') }}
          >

            Place Order
          </Typography>
          <IconButton color="inherit">
            <AccountCircleIcon onClick={() => {
              handleClickOpenProfile()
              getMtShop()
            }} />
          </IconButton>
          <IconButton color="inherit">
            <Badge badgeContent={Object.keys(count).length} color="secondary">
              <ShoppingCartIcon onClick={() => {
                // if(Object.keys(count).length <= 0){
                //   setColor('error')
                //   setErr("Please Select Products")
                //   setOpenSnack(true)
                //   return
                // }else{
                //   handleClickOpenItem()
                // }
                handleClickOpenItem()
              }} />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="opaque"
        anchor="left"
        open={openDraw}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {mainListItems}
          {/* <ListItemButton >
        <ListItemIcon>
          <ShowChartIcon />
        </ListItemIcon>
        <ListItemText primary="Stocks Management" />
        {openList ? <ExpandMore /> : <ExpandMore />}
      </ListItemButton> */}
          {/* <Collapse in={openList}  unmountOnExit>
             <List component="div"  disablePadding>
            <ListItemButton sx={{ pl: 4 }}  to='/dailystock'>
            <ListItemIcon>
              <OpenInNewIcon />
            </ListItemIcon>
            <ListItemText primary="Daily Stock"/>
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} to='/weeklystock'>
            <ListItemIcon>
              <OpenInNewIcon />
            </ListItemIcon>
            <ListItemText primary="Weekly Stock" />
          </ListItemButton>
        </List>
      </Collapse> */}
        </List>
        <Divider />
        <List>
          {secondaryListItems}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 3, mb: 3 }}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{

                  display: 'flex',
                  flexDirection: 'column',
                  // height: 100,
                }}
              >
                <Grid container direction='row' justifyContent="space-evenly" alignItems="center">
                  <Grid item xs={12} md={12} ml={1} mt={1} lg={12} xl={12}>
                    <Box >
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="basic tabs example">
                          {selectedRow.map((val, ind) => {
                            return <Tab label={val.category_name} {...a11yProps(ind)} />
                          })}
                        </Tabs>
                      </Box>
                      {selectedRow && selectedRow.map((val, ind) => (
                        <CustomTabPanel value={value} index={ind}>
                          <Grid container spacing={1} alignItems="center" direction="row">
                            <Grid item xs={12} sm={12} lg={12} xl={12} mb={1} mt={1}>
                              {ismobile ?
                                <Grid container spacing={1} direction='row'>
                                   {selectedRow[value]["result"].map((v, i) => (
                                  <Grid item xs={6}>
                                   
                                      <Card sx={{ marginTop: 2, maxHeight:350 }}>
                                        <>
                                          <CardMedia
                                            component="img"
                                            height="134"
                                            image={v.product_logo}
                                            alt="Paella dish"
                                            style={{ cursor: "pointer" }} onClick={(e) => {
                                              handleClickOpenImg(e, v)
                                            }}
                                          />
                                          <CardContent>
                                            <Grid container>
                                              <Grid item xs={12} align="center" >
                                                <Typography variant="body2" sx={{height:40}} fontSize={12} fontWeight={700} >{v.product_name}</Typography>
                                              </Grid>
                                            </Grid>
                                            <Grid container>
                                              <Grid item xs={12} align="center">
                                                <Typography fontFamily='-moz-initial' variant="body2" fontSize={15} fontWeight={500} >Price -  ₹&nbsp;{v.product_rate}</Typography>
                                              </Grid>
                                            </Grid>
                                            <Divider variant='fullWidth' sx={{my:1}} />
                                            <Grid container spacing={1} direction='row' justifyContent="center" mt={1}>
                                              <Grid item xs={2.5}  mt={2}  >
                                                <IndeterminateCheckBoxIcon onClick={() => { handleDecrement(v._id) }} />
                                              </Grid>
                                              <Grid item xs={6}  align="center" >
                                                <TextField type='number' value={v._id in count ? count[v._id] : 0} InputProps={{ alignItems: 'center' }} onChange={(e) => {
                                                  handleIncrement(v._id, e.target.value)
                                                }} sx={{ height: 5 }} />
                                              </Grid>
                                              <Grid item xs={2} mt={2}>
                                                <AddBoxIcon onClick={() => { handleIncrement(v._id) }} />
                                              </Grid>
                                            </Grid>
                                          </CardContent>
                                        </>
                                      </Card>
                                  </Grid>
                                  ))}
                                </Grid>
                                :
                                <TableContainer component={Paper}>
                                  <Table aria-label="simple table">
                                    <TableHead>
                                      <TableRow variant="scrollable">
                                        <TableCell sx={{ fontWeight: 'bold' }} align="center">Image</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }} align="center">Product Name</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }} align="center">Unit</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }} align="center">Price</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }} align="center">GST</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', marginLeft: '5rem' }} align="center">
                                          <Grid container spacing={1} justifyContent="center" >
                                            <Grid item xs={2} mt={2} mr='3rem' mb="1rem" >
                                              Quantity
                                            </Grid>
                                          </Grid>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {selectedRow[value]["result"].map((v, i) => (
                                        <TableRow
                                          key={v}
                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                          <TableCell align="center">
                                            <img align='center' src={v.product_logo} alt='image' height={56} weight={56} style={{ cursor: "pointer" }} onClick={(e) => {
                                              handleClickOpenImg(e, v)
                                            }} />
                                          </TableCell>
                                          <TableCell align="center">{v.product_name}</TableCell>
                                          <TableCell align="center">{v.product_unit}</TableCell>
                                          <TableCell align="center">{v.product_rate}</TableCell>
                                          {v.product_gst > 1 ? <TableCell align="center">{v.product_gst}</TableCell> : <TableCell align="center">--</TableCell>}
                                          <TableCell align="center">
                                            <Grid container justifyContent="center">
                                              <Grid item xs={2.5} sm={2} md={2} mt={2} lg={2} >
                                                <IndeterminateCheckBoxIcon onClick={() => { handleDecrement(v._id) }} />
                                              </Grid>
                                              <Grid item xs={6} sm={5} lg={2.5} md={5} align="center" ml={1}>
                                                <TextField type='number' value={v._id in count ? count[v._id] : 0} InputProps={{ alignItems: 'center' }} onChange={(e) => {
                                                  handleIncrement(v._id, e.target.value)
                                                }} sx={{ height: 5 }} />
                                              </Grid>
                                              <Grid item xs={2.5} sm={3} mt={2} md={3} lg={3} ml={1}>
                                                <AddBoxIcon onClick={() => { handleIncrement(v._id) }} />
                                              </Grid>
                                            </Grid>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>}
                            </Grid>
                          </Grid>
                        </CustomTabPanel>
                      ))}
                      {ismobile ?
                        <Grid container align='center' sx={{ mb: 3 }}>
                          <Grid xs={12}>
                            {value >= 1 && <Button variant='contained' onClick={handleLeftArrowBtn}><ArrowCircleLeftIcon /></Button>}&nbsp;&nbsp;&nbsp;
                            <Button variant='contained' onClick={() => {
                              handleClickOpenItem()
                            }}>Place Order</Button>&nbsp;&nbsp;&nbsp;{value < 4 && <Button onClick={handleRightArrowBtn} variant='contained'><ArrowCircleRightIcon /></Button>}
                          </Grid>
                        </Grid> :
                        <Grid container align='center' direction="row" sx={{ mb: 3 }}>
                          <Grid xs={12}>
                            {value >= 1 && <Button variant='contained' onClick={handleLeftArrowBtn}><ArrowCircleLeftIcon /></Button>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button variant='contained' onClick={() => {
                              handleClickOpenItem()
                            }}>Place Order</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {value < 4 && <Button onClick={handleRightArrowBtn} variant='contained'><ArrowCircleRightIcon /></Button>}
                          </Grid>
                        </Grid>}
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Dialog
            open={openProfile}
            onClose={handleCloseProfile}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogContent>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs centered value={valueProfile} onChange={handleChangeProfile} aria-label="basic tabs example">
                    <Tab label="Company Details" {...a11yProps(0)} />
                    <Tab label="Change Password" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={valueProfile} index={0}>
                  <Grid container spacing={2} align="center" mt={1}>
                    <Grid item xs={12} paddingBottom="5px">
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        id="standard-disabled"
                        label="Username"
                        disabled={true}
                        value={phoneNumber}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        onChange={(e) => {
                          setPhoneNumber(e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} align="center" mt={1}>
                    <Grid item xs={12} paddingBottom="5px">
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        id="standard-disabled"
                        label="Shop ID"
                        disabled={true}
                        value={shop_id}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        onChange={(e) => {
                          setShopId(e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} align="center" mt={1}>
                    <Grid item xs={12} paddingBottom="5px">
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        id="standard-disabled"
                        label="Shop Name"
                        disabled={true}
                        value={shopname}
                        type="text"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        onChange={(e) => {
                          setShopName(e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} align="center" mt={1}>
                    <Grid item xs={12} paddingBottom="5px">
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        id="standard-disabled"
                        label="Shop Location"
                        disabled={true}
                        type="text"
                        value={shopLocation}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        onChange={(e) => {
                          setShopLocation(e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} align="center" mt={1}>
                    <Grid item xs={12} paddingBottom="5px">
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        id="standard-disabled"
                        label="Owner Name"
                        disabled={true}
                        value={ownerName}
                        type="text"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        onChange={(e) => {
                          setOwnerName(e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={valueProfile} index={1}>
                  <Grid container spacing={2} align="center" mt={1}>
                    <Grid item xs={12} paddingBottom="5px">
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        id="standard-disabled"
                        label="Current Password"
                        type="password"
                        InputProps={{
                          readOnly: false,
                        }}
                        variant="outlined"
                        onChange={(e) => {
                          setCurrentPassword(e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} align="center" mt={1}>
                    <Grid item xs={12} paddingBottom="5px">
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        id="standard-disabled"
                        label="New Password"
                        type="password"
                        InputProps={{
                          readOnly: false,
                        }}
                        variant="outlined"
                        onChange={(e) => {
                          setNewPassword(e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} align="center" mt={1}>
                    <Grid item xs={12} paddingBottom="5px">
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        id="standard-disabled"
                        label="Confirm Password"
                        type="password"
                        InputProps={{
                          readOnly: false,
                        }}
                        variant="outlined"
                        onChange={(e) => {
                          setConfirmPassword(e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ display: "flex", justifyContent: "center", mt: "2rem", mr: "2rem" }}>
                    <Box sx={{ mx: "2rem" }}>
                      <Button variant="contained" onClick={handleCloseProfile}>
                        cancel
                      </Button>
                    </Box>
                    <Button variant="contained" onClick={() => {
                      if (!currentpassword) {
                        setColor('error')
                        setErr("Please Enter Current Password")
                        setOpenSnack(true)
                        return
                      }
                      if (!newpassword) {
                        setColor('error')
                        setErr("Please Enter New Password")
                        setOpenSnack(true)
                        return
                      }
                      if (newpassword.length < 8) {
                        setColor('error')
                        setErr("Your Password must be 8 character")
                        setOpenSnack(true)
                        return
                      }
                      if (currentpassword == newpassword) {
                        setColor('error')
                        setErr("currentPassword & newpassword are same credential!")
                        setOpenSnack(true)
                        return
                      }
                      if (!confirmpassword) {
                        setColor('error')
                        setErr("Please Enter Confirm Password")
                        setOpenSnack(true)
                        return
                      }
                      if (newpassword != confirmpassword) {
                        setColor('error')
                        setErr("Given Password Does Not Match!")
                        setOpenSnack(true)
                        return
                      }
                      handleCloseProfile()
                      updatePassword()
                    }}>
                      Save
                    </Button>

                  </Box>
                </CustomTabPanel>
              </Box>
            </DialogContent>
          </Dialog>

          <Dialog
            open={openImg}
            onClose={handleCloseImg}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogContent>
              <img align='center' src={img} alt='image' width='100%' height='100%' />
            </DialogContent>
            <DialogActions>
              <Button variant='contained' onClick={handleCloseImg}>Cancel</Button>
            </DialogActions>
          </Dialog>
          {ismobile ?
            <Dialog
              open={openItem}
              onClose={handleCloseItem}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Grid container direction="row">
                    <Grid xs={12}>
                      {ismobile ?
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }} >  Product&nbsp;Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Quantity</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Price&nbsp;(₹)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">GST&nbsp;(₹)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Total&nbsp;(₹)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(count).length > 0 ? Object.keys(count).map((row) => (
                                console.log(count, "uekj"),
                                <TableRow
                                  key={row}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    {allProductJson[row]["product_name"]}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="center">
                                    {count[row]}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {allProductJson[row]["product_rate"]}
                                  </TableCell>
                                  <TableCell align="center">{((allProductJson[row]["product_gst"] / 100) * (count[row] * parseFloat(allProductJson[row]["product_rate"]))).toFixed(2)}</TableCell>
                                  <TableCell align="center">{((count[row] * parseFloat(allProductJson[row]["product_rate"])) + ((allProductJson[row]["product_gst"] / 100) * (count[row] * parseFloat(allProductJson[row]["product_rate"])))).toFixed(2)}</TableCell>
                                  <TableCell align="center">{<DeleteIcon sx={{ color: 'red', cursor: 'pointer' }} onClick={(e) => {
                                    handleDelete(row)
                                  }}
                                  >
                                  </DeleteIcon>}</TableCell>
                                </TableRow>
                              )) : (<TableRow
                                key={"Total"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row" >
                                  {"Cart Empty"}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                              </TableRow>)}
                              <TableRow
                                key={"Total"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                  {"Total"}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {Object.keys(count).length > 0 && "₹" + Object.keys(count).reduce((a, v) => a = a + parseFloat(count[v] * parseFloat(allProductJson[v]["product_rate"]) + ((allProductJson[v]["product_gst"] / 100) * (count[v] * parseFloat(allProductJson[v]["product_rate"])))), 0).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        :
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }} >  Product&nbsp;Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Quantity</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Price&nbsp;(₹)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">GST&nbsp;(₹)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Total&nbsp;(₹)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(count).length > 0 ? Object.keys(count).map((row) => (
                                <TableRow
                                  key={row}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    {allProductJson[row]["product_name"]}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="center">
                                    {count[row]}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {allProductJson[row]["product_rate"]}
                                  </TableCell>
                                  <TableCell align="center">{((allProductJson[row]["product_gst"] / 100) * (count[row] * parseFloat(allProductJson[row]["product_rate"]))).toFixed(2)}</TableCell>
                                  <TableCell align="center">{((count[row] * parseFloat(allProductJson[row]["product_rate"])) + ((allProductJson[row]["product_gst"] / 100) * (count[row] * parseFloat(allProductJson[row]["product_rate"])))).toFixed(2)}</TableCell>
                                  <TableCell align="center">{<DeleteIcon sx={{ color: 'red', cursor: 'pointer' }} onClick={(e) => {
                                    handleDelete(row)
                                  }}
                                  >
                                  </DeleteIcon>}</TableCell>
                                </TableRow>
                              )) : (<TableRow
                                key={"Total"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {"Cart Empty"}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                              </TableRow>)}
                              <TableRow
                                key={"Total"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                  {"Total"}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {Object.keys(count).length > 0 && "₹" + Object.keys(count).reduce((a, v) => a = a + parseFloat(count[v] * parseFloat(allProductJson[v]["product_rate"]) + ((allProductJson[v]["product_gst"] / 100) * (count[v] * parseFloat(allProductJson[v]["product_rate"])))), 0).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>}
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions alignItems='center'>
                <Grid container align='center' mb={2}>
                  <Grid xs={12} >
                    <Button variant='contained' onClick={() => {
                      handleCloseItem()
                    }}>cancel</Button>&nbsp;&nbsp;
                    {Object.keys(count).length > 0 && <Button variant='contained' onClick={() => {
                      handleCloseItem()
                      if (queryValue.length) {
                        editOrders();
                      } else {
                        PlaceOrders();
                      }

                    }}>Place Order</Button>}
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog> : <Dialog
              open={openItem}
              onClose={handleCloseItem}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  width: '95%',
                  height: '90%',
                  margin: 0,
                  maxWidth: 'none',
                  maxHeight: 'none',
                },
              }}
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Grid container direction="row">
                    <Grid xs={12}>
                      {ismobile ?
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }} >  Product&nbsp;Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Quantity</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Price&nbsp;(₹)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">GST&nbsp;(₹)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Total&nbsp;(₹)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(count).length > 0 ? Object.keys(count).map((row) => (
                                console.log(count, "uekj"),
                                <TableRow
                                  key={row}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    {allProductJson[row]["product_name"]}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="center">
                                    {count[row]}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {allProductJson[row]["product_rate"]}
                                  </TableCell>
                                  <TableCell align="center">{((allProductJson[row]["product_gst"] / 100) * (count[row] * parseFloat(allProductJson[row]["product_rate"]))).toFixed(2)}</TableCell>
                                  <TableCell align="center">{((count[row] * parseFloat(allProductJson[row]["product_rate"])) + ((allProductJson[row]["product_gst"] / 100) * (count[row] * parseFloat(allProductJson[row]["product_rate"])))).toFixed(2)}</TableCell>
                                  <TableCell align="center">{<DeleteIcon sx={{ color: 'red', cursor: 'pointer' }} onClick={(e) => {
                                    handleDelete(row)
                                  }}
                                  >
                                  </DeleteIcon>}</TableCell>
                                </TableRow>
                              )) : (<TableRow
                                key={"Total"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row" >
                                  {"Cart Empty"}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                              </TableRow>)}
                              <TableRow
                                key={"Total"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                  {"Total"}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {Object.keys(count).length > 0 && "₹" + Object.keys(count).reduce((a, v) => a = a + parseFloat(count[v] * parseFloat(allProductJson[v]["product_rate"]) + ((allProductJson[v]["product_gst"] / 100) * (count[v] * parseFloat(allProductJson[v]["product_rate"])))), 0).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        :
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }} >  Product&nbsp;Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Quantity</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Price&nbsp;(₹)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">GST&nbsp;(₹)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Total&nbsp;(₹)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(count).length > 0 ? Object.keys(count).map((row) => (
                                <TableRow
                                  key={row}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row" >
                                    {allProductJson[row]["product_name"]}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="center">
                                    {count[row]}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align='center'>
                                    {allProductJson[row]["product_rate"]}
                                  </TableCell>
                                  <TableCell align="center">{((allProductJson[row]["product_gst"] / 100) * (count[row] * parseFloat(allProductJson[row]["product_rate"]))).toFixed(2)}</TableCell>
                                  <TableCell align="center">{((count[row] * parseFloat(allProductJson[row]["product_rate"])) + ((allProductJson[row]["product_gst"] / 100) * (count[row] * parseFloat(allProductJson[row]["product_rate"])))).toFixed(2)}</TableCell>
                                  <TableCell align="center">{<DeleteIcon sx={{ color: 'red', cursor: 'pointer' }} onClick={(e) => {
                                    handleDelete(row)
                                  }}
                                  >
                                  </DeleteIcon>}</TableCell>
                                </TableRow>
                              )) : (<TableRow
                                key={"Total"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" align='center'>
                                  {"Cart Empty"}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                              </TableRow>)}
                              <TableRow
                                key={"Total"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                  {"Total"}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {""}
                                </TableCell>
                                <TableCell component="th" scope="row" align='center'>
                                  {Object.keys(count).length > 0 && "₹" + Object.keys(count).reduce((a, v) => a = a + parseFloat(count[v] * parseFloat(allProductJson[v]["product_rate"]) + ((allProductJson[v]["product_gst"] / 100) * (count[v] * parseFloat(allProductJson[v]["product_rate"])))), 0).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>}
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions alignItems='center'>
                <Grid container align='center' mb={2}>
                  <Grid xs={12} >
                    <Button variant='contained' onClick={() => {
                      handleCloseItem()
                    }}>cancel</Button>&nbsp;&nbsp;
                    {Object.keys(count).length > 0 && <Button variant='contained' onClick={() => {
                      handleCloseItem()
                      console.log(Object.keys(count).length, queryValue.length, "Gja");
                      if (queryValue.length) {
                        editOrders();
                      } else {
                        if (Object.keys(count).length) {
                          PlaceOrders();
                        } else {
                          setColor('error')
                          setErr("Please Select Products")
                          setOpenSnack(true)
                        }

                      }

                    }}>Place Order</Button>}
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>}
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }} open={openSnack} autoHideDuration={1500} onClose={handleCloseSnack}>
              <Alert onClose={handleCloseSnack} severity={color} sx={{ width: '100%' }}>
                {err}
              </Alert>
            </Snackbar>
          </Stack>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}